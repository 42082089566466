body {
	font-family: Montserrat, sans-serif;
	color: #677179;
	font-size: 1.125rem;
	line-height: 1.5em;
	font-weight: 300;
}

h1 {
	margin-top: 0.15rem;
	margin-bottom: 0.5em;
	font-family: Rajdhani, sans-serif;
	color: #13456c;
	font-size: 2em;
	line-height: 1.125em;
	font-weight: 500;
	letter-spacing: 0.01em;
}

h2 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	font-family: Rajdhani, sans-serif;
	color: #13456c;
	font-size: 1.6em;
	line-height: 1.1875em;
	font-weight: 500;
	letter-spacing: 0.01em;
}

h3 {
	margin-top: 1.5em;
	margin-bottom: 0.5em;
	font-family: Rajdhani, sans-serif;
	color: #13456c;
	font-size: 1.5em;
	line-height: 1.25em;
	font-weight: 500;
	letter-spacing: 0.01em;
}

h4 {
	margin-top: 1em;
	margin-bottom: 0.5em;
	font-family: Rajdhani, sans-serif;
	color: #13456c;
	font-size: 1.25em;
	line-height: 1.25em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h5 {
	margin-top: 1.375em;
	margin-bottom: 0.75em;
	font-family: Rajdhani, sans-serif;
	color: #13456c;
	font-size: 0.9375em;
	line-height: 1.25em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

h6 {
	margin-top: 1.5em;
	margin-bottom: 0.875em;
	font-family: Rajdhani, sans-serif;
	color: #13456c;
	font-size: 0.8125em;
	line-height: 1.25em;
	font-weight: 600;
	letter-spacing: 0.01em;
}

p {
	margin-top: 0em;
	margin-bottom: 0.75em;
}

a {
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	color: #15598f;
	text-decoration: none;
}

a:hover {
	color: #09314c;
	text-decoration: none;
}

ul {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1.125em;
}

ol {
	margin-top: 0.5em;
	margin-bottom: 0.75em;
	padding-left: 1em;
}

li {
	margin-bottom: 0.25em;
}

img {
	display: inline-block;
	max-width: 100%;
}

label {
	display: block;
	line-height: 1.25em;
}

strong {
	font-weight: 500;
	letter-spacing: 0.02em;
}

em {
	font-style: italic;
	letter-spacing: 0.02em;
}

blockquote {
	margin-top: 1em;
	margin-bottom: 1em;
	padding: 1.375em 1.25em;
	border-left: 5px solid #8d999f;
	background-color: #09314c;
	color: hsla(0, 0%, 100%, 0.9);
	font-size: 1.0625em;
	line-height: 1.375em;
}

.button {
	display: inline-block;
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.5em 1.3em;
	background-color: #13456c;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.9375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.02em;
	text-decoration: none;
	text-transform: uppercase;
}

.button:hover {
	background-color: #09314c;
	color: #fff;
}

.button.secondary {
	background-color: #677179;
}

.button.secondary:hover {
	background-color: #09314c;
}

.button.tertiary {
	background-color: #8d999f;
}

.button.tertiary:hover {
	background-color: #09314c;
}

.button.header-search-button {
	width: 3.5rem;
	margin-top: 0em;
	margin-bottom: 0em;
	padding-top: 0em;
	padding-bottom: 0em;
	background-color: #000;
	background-image: url('/20150114134215/assets/images/search_wht.svg');
	background-position: 50% 50%;
	background-size: 1em 1em;
	background-repeat: no-repeat;
	color: transparent;
	font-size: 1rem;
	line-height: 1em;
	text-transform: uppercase;
}

.button.header-search-button:hover {
	background-color: #13456c;
}

.button.slider-button {
	padding: 1em 1.5em;
	background-color: transparent;
	box-shadow: inset 0 0 0 3px #8d999f;
	font-size: 0.75em;
	line-height: 1.25em;
	font-weight: 500;
	text-transform: uppercase;
}

.button.slider-button:hover {
	box-shadow: inset 0 0 0 3px #fff;
}

.button.intro-cbox-learn-more {
	padding: 1em 1.75em;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	font-size: 0.75em;
	line-height: 1.25em;
}

.container {
	max-width: 1900px;
	padding-right: 20px;
	padding-left: 20px;
}

.container.home-cbox-container {
	max-width: 100%;
	padding: 5vw 4vw;
}

.container.home-cbox-container.home-intro-news-container {
	border-top: 10px solid #fff;
	background-color: #e8f3f8;
}

.container.footer-container {
	max-width: 1900px;
	padding-right: 4vw;
	padding-left: 4vw;
}

.container.inside-container {
	margin-top: 7rem;
	padding: 25px 4vw 4vw;
}

.nav-section {
	left: 0px;
	top: 0px;
	right: 0px;
	width: 100%;
	max-width: 100%;
	background-color: transparent;
	position: fixed;
}

.nav-container {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	height: 120px;
	max-width: 1740px;
	padding-left: 20px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
}

.sticky .nav-container {
	height: 70px;
	transition: height 700ms ease 0;
}


.nav-link {
	display: inline-block;
	margin-right: 1px;
	padding: 0.875rem 0.5rem 0.75rem;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	background-image: url('/20150114134215/assets/images/tab-01.svg');
	background-position: 50% 125%;
	background-size: 50px 4px;
	background-repeat: no-repeat;
	box-shadow: none;
	color: #13456c;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.nav-link:hover {
	color: rgba(9, 49, 76, 0.5);
}

.nav-link.w--current {
	background-position: 50% 104%;
	color: #13456c;
}

.nav-link.w--current:hover {
	color: #677179;
}

.nav-link.secondary-nav-button {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 5rem;
	min-width: 3rem;
	padding: 0rem;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	box-shadow: none;
	opacity: 0.9;
	-webkit-transition: all 250ms ease;
	transition: all 250ms ease;
	cursor: pointer;
}

.nav-link.secondary-nav-button:hover {
	box-shadow: none;
	opacity: 0.7;
}

.nav-menu-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	max-width: 740px;
	margin-top: 0px;
	float: left;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 740px;
	-ms-flex-preferred-size: 740px;
	flex-basis: 740px;
	border-left: 1px none rgba(0, 0, 0, 0.15);
}

.logo {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 220px;
	margin-top: 0em;
	margin-right: 80px;
	float: left;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-self: center;
	-ms-flex-item-align: center;
	align-self: center;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	border-bottom-style: none;
	-webkit-transition-duration: 500ms;
	transition-duration: 500ms;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1.25rem;
	font-weight: 600;
	text-align: center;
}

.logo:hover {
	color: #fff;
}

.footer-section {
	position: relative;
	padding-top: 0rem;
	background-color: #e8f3f8;
	background-image: -webkit-linear-gradient(270deg, rgba(220, 238, 246, 0.75), rgba(220, 238, 246, 0.75)), url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-image: linear-gradient(180deg, rgba(220, 238, 246, 0.75), rgba(220, 238, 246, 0.75)), url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 0px 0px, 50% 0px;
	color: #677179;
	font-size: 0.9375rem;
	line-height: 1.5em;
}

.row.footer-signoff-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.row.footer-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.row.home-cbox-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.row.portfolio-category-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: -2%;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
}

.column.home-cbox-column {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.column.footer-signoff-right-col {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}


.logo-image {
	transform: scale(1);
	padding-top: 2px;
	padding-bottom: 5px;
	transition: transform 700ms ease;
}

.sticky .logo-image {
	transition: transform 700ms ease;
	transform: scale(0.65);
}

.footer-signoff-section {
	margin-top: 0.5rem;
	padding-top: 2rem;
	padding-bottom: 3rem;
	background-color: #fff;
	color: #8d999f;
	font-size: 0.6875rem;
	line-height: 1rem;
	font-weight: 400;
}

.copyright-text {
	display: inline-block;
	margin-right: 0rem;
	margin-left: 0rem;
}

.footer-signoff-list {
	display: inline-block;
	margin: 0rem 1rem;
	padding-left: 0px;
}

.footer-signoff-list-item {
	display: inline-block;
	margin-bottom: 0em;
	padding-right: 0.5rem;
	padding-left: 0.5rem;
}

.footer-signoff-grip {
	width: 130px;
	height: 28px;
	margin-right: 1em;
	margin-left: 1em;
	padding-left: 32px;
	float: right;
	border-bottom-style: none;
	border-bottom-width: 0px;
	background-image: url('/20150114134215/assets/images/globalreach_flat_gray.png');
	background-size: 130px 28px;
	background-repeat: no-repeat;
	font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
	color: #999;
	font-size: 8px;
	line-height: 9px;
	font-weight: 600;
	text-transform: uppercase;
}

.footer-signoff-grip:hover {
	color: #999;
}

.footer-column-wrap {
	margin-bottom: 1rem;
	padding-right: 0em;
	padding-bottom: 1rem;
	clear: both;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: auto;
	-ms-flex-preferred-size: auto;
	flex-basis: auto;
}

.footer-column-title {
	margin-top: 1rem;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	font-family: Rajdhani, sans-serif;
	color: #13456c;
	font-size: 1.375em;
	line-height: 1.25em;
	font-style: normal;
	font-weight: 600;
	text-transform: uppercase;
}

.header-content-right-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	float: right;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-flex-wrap: nowrap;
	-ms-flex-wrap: nowrap;
	flex-wrap: nowrap;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.header-contents-wrap {
	position: relative;
	z-index: 5000;
	display: none;
	height: 50px;
}

.two-part-button-wrap {
	position: relative;
	display: block;
	max-width: 768px;
	margin-right: auto;
	margin-left: auto;
}

.breadcrumb-list {
	margin: 0em 0.125rem 0rem;
	padding-top: 0.25em;
	padding-left: 0px;
	font-family: Rajdhani, sans-serif;
	color: #9aafb8;
	font-size: 0.8125rem;
	line-height: 1em;
	font-weight: 500;
	text-transform: uppercase;
}

.breadcrumb-list-item {
	margin-top: 0.25rem;
	margin-right: 0.5rem;
	margin-bottom: 0.25rem;
	float: left;
}

.header-menu-wrap {
	width: 100%;
	max-width: 100%;
	background-color: rgba(220, 238, 246, 0.85);
	background-image: -webkit-linear-gradient(0deg, rgba(238, 245, 248, 0.25), transparent 50%), -webkit-linear-gradient(0deg, rgba(235, 245, 250, 0.85), rgba(220, 238, 246, 0.5)), url('/20150114134215/assets/images/diagonal-noise-1.png'), -webkit-linear-gradient(0deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0.15));
	background-image: linear-gradient(90deg, rgba(238, 245, 248, 0.25), transparent 50%), linear-gradient(90deg, rgba(235, 245, 250, 0.85), rgba(220, 238, 246, 0.5)), url('/20150114134215/assets/images/diagonal-noise-1.png'), linear-gradient(90deg, hsla(0, 0%, 100%, 0.8), hsla(0, 0%, 100%, 0.15));
	background-position: 0px 0px, 0px 0px, 50% 0px, 0px 0px;
	box-shadow: none;
}

.form-wrap {
	margin-bottom: 0px;
}

.form-field {
	position: relative;
	width: auto;
	margin-bottom: 0.75em;
	padding: 4px 10px;
	font-size: 0.875em;
}

.form-field.header-search-field {
	width: 150px;
	height: 3rem;
	margin-bottom: 0em;
	padding-right: 1em;
	padding-left: 1em;
	border-style: solid;
	border-width: 1px;
	border-color: rgba(0, 0, 0, 0.25);
	border-radius: 0px;
	background-color: hsla(0, 0%, 100%, 0.15);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
	color: #000;
	font-size: 0.75em;
	line-height: 1.25em;
	font-weight: 400;
}

.form-field.header-search-field:focus {
	width: 300px;
	background-color: #fff;
}

.form-success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #677179;
	color: #fff;
}

.form-error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c71741;
	color: #fff;
}

.secondary-nav-list {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-top: 0em;
	margin-right: 10px;
	margin-bottom: 0rem;
	padding-left: 0em;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	font-size: 1rem;
	line-height: 1.25em;
}

.secondary-nav-list-item {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-bottom: 0em;
	padding-right: 2px;
	padding-left: 2px;
	float: left;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
	border-right: 1px none rgba(0, 0, 0, 0.15);
}

.secondary-nav-list-item.first {
	border-left-style: none;
}

.breadcrumb-divider {
	margin-left: 0.5rem;
	float: right;
}

.right-sidebar-column {
	padding-top: 1.5vw;
}

.left-nav-list {
	margin-top: 0em;
	margin-bottom: 1.5em;
	padding-left: 0px;
	box-shadow: 0 1px 0 0 #d5e1e6;
}

.left-nav-list-item {
	margin-bottom: 1px;
	box-shadow: 0 -1px 0 0 #d5e1e6;
}

.left-nav-list-link {
	display: block;
	padding: 0.6875em 1em 0.5625em 0.875em;
	background-image: url('/20150114134215/assets/images/icon_arrow2_right_blk_25.svg');
	background-position: 6px 19px;
	background-size: 7px 10px;
	background-repeat: no-repeat;
	font-family: Rajdhani, sans-serif;
	color: #15598f;
	font-size: 1.25rem;
	line-height: 1.0625em;
	font-weight: 600;
	letter-spacing: -0.02em;
}

.left-nav-list-link:hover {
	color: #8ba8b6;
}

.left-nav-list-link.w--current {
	background-color: #f4fafc;
}

.left-nav-list-link.w--current:hover {
	color: #15598f;
}

.left-nav-nested-list {
	margin-top: 0em;
	margin-bottom: 0em;
	padding: 0em 0em 0em 0.75em;
	border-top: 2px solid #15598f;
	background-image: none;
}

.left-nav-nested-list-item {
	margin-bottom: 1px;
	box-shadow: 0 1px 0 0 #d5e1e6;
}

.left-nav-nested-list-link {
	display: block;
	padding: 0.75rem 1.5em 0.6875rem 1.125em;
	background-image: url('/20150114134215/assets/images/icon_arrow2_right_blk_25.svg');
	background-position: 6px 18px;
	background-size: 6px 8px;
	background-repeat: no-repeat;
	color: #15598f;
	font-size: 0.8125em;
	line-height: 1.375em;
	font-weight: 400;
}

.left-nav-nested-list-link:hover {
	color: #8ba8b6;
}

.left-nav-nested-list-link.w--current {
	background-color: #f4fafc;
	font-weight: 500;
}

.left-nav-nested-list-link.w--current:hover {
	color: #15598f;
}

.sidebar-section-wrap {
	position: relative;
	margin-bottom: 2.5em;
	margin-left: 3vw;
	padding-top: 0.75em;
	border-top: 3px solid #d5e1e6;
	font-size: 1rem;
	line-height: 1.5em;
}

.sidebar-section-title {
	margin-top: 0.25em;
	margin-bottom: 0.625em;
	font-size: 1.375em;
	line-height: 1.0625em;
}

.sidebar-list-item {
	margin-bottom: 0.75em;
	padding-left: 0px;
}

.secondary-nav-icon {
	width: 1rem;
	height: 1rem;
	margin-right: 0.5em;
	float: left;
}

.secondary-nav-icon.tel-icon {
	width: 1.25em;
	height: 1.25em;
}

.secondary-nav-text {
	float: left;
}

.secondary-nav-list-link {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0.75em;
	padding-left: 0.5em;
	float: none;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	border-bottom-style: none;
	font-size: 0.6875rem;
	line-height: 1.125em;
	font-weight: 500;
	text-align: center;
	text-transform: uppercase;
}

.secondary-nav-list-link:hover {
	box-shadow: inset 0 2px 0 0 #13456c;
	color: #13456c;
}

.slider-section {
	position: relative;
}

.slider {
	display: block;
	height: 100vh;
	margin-right: auto;
	margin-left: auto;
	background-color: transparent;
}

.slider-arrow {
	left: auto;
	top: auto;
	right: 6%;
	bottom: 2rem;
	width: 3.5rem;
	height: 2.5rem;
	border-radius: 0em;
	background-color: #09314c;
	opacity: 0.75;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	color: #fff;
	font-size: 0.875rem;
}

.slider-arrow:hover {
	opacity: 1;
	color: #fff;
}

.slider-arrow.left-slider-arrow {
	margin-right: 3.75rem;
}

.slider-indicator {
	left: auto;
	right: 6%;
	bottom: 2rem;
	display: block;
	width: auto;
	height: 2.5rem;
	margin-right: 7.5rem;
	margin-left: auto;
	padding: 1rem 1rem 0rem;
	border-radius: 5em;
	background-image: none;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.5rem;
	line-height: 1em;
	text-align: right;
}

.home-image-link {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	height: 15vw;
	max-height: 200px;
	min-height: 160px;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	background-color: #f3f5f7;
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.home-image-link.large-image-link {
	position: relative;
	overflow: visible;
	height: 18vw;
	max-height: 400px;
	min-height: 200px;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 0;
	-webkit-flex: 0 auto;
	-ms-flex: 0 auto;
	flex: 0 auto;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 1.75rem;
	line-height: 1em;
	font-weight: 500;
	text-shadow: 0 2px 3px rgba(5, 34, 53, 0.3);
}


.home-image-link.large-image-link.large-image-1 {
	background-image: url('/20150114134215/assets/images/slide-05.jpg');
}

.home-image-link.large-image-link.large-image-2 {
	background-image: url('/20150114134215/assets/images/slide-16.jpg');
}

.home-image-link.large-image-link.large-image-3 {
	background-image: url('/20150114134215/assets/images/slide-18.jpg');
}


.home-image-link.intro-cbox-image-link {
	position: relative;
	overflow: visible;
	height: 25vw;
	max-height: 500px;
	min-height: 400px;
	min-width: 49%;
	margin-right: 4%;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	border: 2px solid #fff;
}

.home-content-box-wrap {
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
}

.home-content-box-wrap.large-content-box-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	width: 100%;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.home-content-box-title {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding-top: 0.125em;
	font-size: 1.375em;
	line-height: 1.375em;
}

.home-content-box-title.intro-cbox-title {
	font-size: 2em;
}

.home-cbox-learn-more {
	display: none;
	margin-top: 0.5rem;
	clear: both;
	-webkit-align-self: flex-start;
	-ms-flex-item-align: start;
	align-self: flex-start;
	font-style: normal;
}

.slider-image {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: block;
	width: 100%;
	height: 100%;
	margin-right: auto;
	margin-left: auto;
	background-image: url('/20150114134215/assets/images/slide-04.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider-image.slide-2 {
	background-image: url('/20150114134215/assets/images/slide-03-2.jpg');
}

.slider-image.slide-3 {
	background-image: url('/20150114134215/assets/images/slide-05.jpg');
}

.slider-image.slide-4 {
	background-image: url('/20150114134215/assets/images/slide-09.jpg');
}

.slider-image.slide-5 {
	background-image: url('/20150114134215/assets/images/slide-02.jpg');
}

.slider-caption-wrap {
	position: absolute;
	left: 4vw;
	top: auto;
	right: auto;
	bottom: 0px;
	display: block;
	max-width: 520px;
	min-width: 320px;
	margin-right: auto;
	margin-left: auto;
	padding: 2rem 80px 1.75rem 25px;
	background-color: rgba(9, 49, 76, 0.95);
	color: rgba(240, 246, 248, 0.9);
	font-size: 1.125rem;
	line-height: 1.5em;
	text-align: left;
}

.inside-page-header-content-wrap {
	position: relative;
	margin-bottom: 1.5em;
	padding-bottom: 0.375em;
	border-bottom: 1px solid #d5e1e6;
}

.inside-page-header-section {
	position: relative;
	height: 15vh;
	min-height: 240px;
	background-color: #09314c;
	background-image: -webkit-linear-gradient(270deg, rgba(9, 49, 76, 0.75), rgba(9, 49, 76, 0.75)), url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-image: linear-gradient(180deg, rgba(9, 49, 76, 0.75), rgba(9, 49, 76, 0.75)), url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 0px 0px, 50% 50%;
}

.main-content-wrap {
	overflow: hidden;
	padding-top: 1.5vw;
}

.header-menu-inside-wrap {
	position: relative;
	display: block;
	width: 100%;
	max-width: 100%;
	padding-right: 3vw;
	padding-left: 3vw;
	box-shadow: none;
}

.intro-text-style {
	font-family: Rajdhani, sans-serif;
	color: #567792;
	font-size: 1.25em;
	line-height: 1.4125em;
	font-weight: 500;
}

.home-cbox-text-wrap {
	width: 100%;
	padding: 0.5rem 0.125em;
}

.home-cbox-text-wrap.large-cbox-text-wrap {
	display: none;
	width: 100%;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
	-webkit-box-flex: 0;
	-webkit-flex-grow: 0;
	-ms-flex-positive: 0;
	flex-grow: 0;
	font-size: 1.0625rem;
	line-height: 1.5em;
}

.home-cbox-text-wrap.intro-cbox-text-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-top: 0rem;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	font-size: 1.0625rem;
}

.slider-caption-title {
	margin-top: 0em;
	margin-bottom: 0.75rem;
	color: #fff;
	line-height: 1.3125em;
	word-wrap: break-word;
}

.footer-links-list-item {
	margin-bottom: 1px;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	-webkit-transition: all 350ms ease;
	transition: all 350ms ease;
}

.footer-list-link {
	display: block;
	padding-top: 0.5rem;
	padding-bottom: 0.5rem;
	font-weight: 400;
}

.footer-social-icons-section-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
}

.footer-social-icon-link {
	margin-top: 4px;
	margin-bottom: 8px;
	margin-left: 0px;
	background-color: transparent;
	opacity: 0.8;
}

.footer-social-icon-link:hover {
	opacity: 1;
}

.footer-social-icon-image {
	width: 32px;
	height: 32px;
	padding: 5px;
}

.left-nav-list-content-wrap {
	display: block;
	height: auto;
	margin-top: -1.5em;
	margin-right: 2.25vw;
	padding-top: 0em;
	padding-bottom: 4vw;
	border-right: 1px solid #d5e1e6;
}

.footer-link {
	display: inline-block;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
	color: #15598f;
}

.footer-link:hover {
	border-bottom-color: rgba(9, 49, 76, 0.5);
	color: #09314c;
}

.link {
	border-bottom: 1px solid #c5d7df;
}

.link:hover {
	border-bottom-style: solid;
	border-bottom-color: #6da7d6;
}

.link.w--current {
	color: #28313b;
	font-weight: 700;
}

.link.h3-link {
	color: #13456c;
	line-height: 1.4125em;
}

.link.h3-link:hover {
	color: #09314c;
}

.link.content-box-title-link {
	border-bottom-color: transparent;
}

.link.content-box-title-link:hover {
	border-bottom-color: rgba(0, 0, 0, 0.15);
	color: #09314c;
}

.link.h2-link {
	color: #13456c;
	line-height: 1.4125em;
}

.link.h2-link:hover {
	color: #09314c;
}

.link.breadcrumb-link {
	display: inline-block;
	border-bottom-color: transparent;
	color: #91a1a8;
}

.link.breadcrumb-link:hover {
	border-bottom-color: #c5d7df;
	color: #15598f;
}

.link.h4-link {
	color: #13456c;
	line-height: 1.5em;
}

.link.h4-link:hover {
	color: #09314c;
}

.link.h5-link {
	color: #13456c;
	line-height: 1.5625em;
}

.link.h5-link:hover {
	color: #09314c;
}

.link.h6-link {
	color: #13456c;
	line-height: 1.5625em;
}

.link.h6-link:hover {
	color: #09314c;
}

.link.link-on-dark-bknd {
	border-bottom-color: rgba(197, 215, 223, 0.4);
	color: #90b7d4;
}

.link.link-on-dark-bknd:hover {
	border-bottom-color: rgba(197, 215, 223, 0.8);
	color: #fff;
}

.divider {
	height: 1px;
	margin-top: 1.5em;
	margin-bottom: 1.5em;
	clear: both;
	background-color: #d5e1e6;
}

.shop-item-row-wrap-grid-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
}

.loc-sidebar-form-reveal {
	overflow: hidden;
	height: auto;
}

.loc-refine-search-button-image {
	width: 0.75em;
	height: 0.75em;
	margin-top: 3px;
}

.pagination-view-by-button {
	width: 2.25rem;
	height: 2.25rem;
	margin-left: 0.5rem;
	border: 1px solid #000;
	opacity: 0.35;
}

.pagination-view-by-button:hover {
	opacity: 1;
}

.inside-page-content-wrap-no-sidebars {
	margin-right: 0%;
	margin-left: 0%;
}

.nav-drop-wrap {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-flex: 1;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	border-right: 1px none rgba(0, 0, 0, 0.15);
}

.nav-drop-toggle {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	padding: 0px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
	-webkit-box-ordinal-group: 0;
	-webkit-order: -1;
	-ms-flex-order: -1;
	order: -1;
	box-shadow: 0 -3px 0 0 transparent;
	-webkit-transition: all 200ms ease;
	transition: all 200ms ease;
	color: #13456c;
}

.nav-drop-toggle:hover {
	color: rgba(9, 49, 76, 0.5);
}

.nav-drop-toggle.w--open:hover {
	color: rgba(9, 49, 76, 0.5);
}

.nav-drop-icon {
	display: none;
	margin-right: 2rem;
	font-size: 0.75em;
}

.nav-drop-list {
	overflow: hidden;
}

.nav-drop-list {
	left: 0px;
	top: 100%;
	padding: 0.5em 0.625em;
	-webkit-box-ordinal-group: 2;
	-webkit-order: 1;
	-ms-flex-order: 1;
	order: 1;
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	background-color: #21272e;
	background-image: -webkit-linear-gradient(270deg, rgba(33, 39, 46, 0.8), rgba(33, 39, 46, 0.8)), url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-image: linear-gradient(180deg, rgba(33, 39, 46, 0.8), rgba(33, 39, 46, 0.8)), url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 0px 0px, 50% 50%;
	color: #fff;
}

.nav-drop-list-link {
	padding: 0.75em 3rem 0.75em 0.5rem;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.375em;
	font-weight: 400;
}

.nav-drop-list-link:hover {
	color: #8d999f;
}

.nav-drop-list-link.w--current {
	color: #a3bcc9;
}

.nav-drop-list-link.w--current:hover {
	color: #8d999f;
}

.nav-drop-toggle-link {
	width: 100%;
	padding: 0.875rem 0.5rem 0.75rem;
	background-image: url('/20150114134215/assets/images/tab-01.svg');
	background-position: 50% 120%;
	background-size: 50px 4px;
	background-repeat: no-repeat;
	color: #13456c;
	font-size: 1.125rem;
	line-height: 1.375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.02em;
	text-transform: uppercase;
}

.nav-drop-toggle-link.request-offer {
	color: #d6260e;
}

.nav-drop-toggle-link:hover {
	color: rgba(9, 49, 76, 0.5);
}

.nav-drop-toggle-link.w--current {
	background-position: 50% 104%;
}

.header-container {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	height: 100%;
	max-width: 1200px;
	padding-right: 10px;
	padding-left: 18px;
	-webkit-box-pack: justify;
	-webkit-justify-content: space-between;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

.shop-item-row-wrap-list-view {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.secondary-header-item-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	font-family: Merriweather, serif;
	font-size: 0.75rem;
	line-height: 1.25em;
	font-style: italic;
	font-weight: 700;
}

.header-content-right-row {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: stretch;
	-webkit-align-items: stretch;
	-ms-flex-align: stretch;
	align-items: stretch;
}

.secondary-header-item-cta-link {
	margin-right: 0.5rem;
	padding: 0.625em 1em;
	-webkit-align-self: stretch;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.25);
	font-size: 0.875rem;
	line-height: 1.125em;
	font-weight: 500;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.secondary-header-item-cta-link:hover {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
}

.header-search-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	margin-right: auto;
	margin-bottom: 0px;
	margin-left: auto;
	padding: 10px;
	float: right;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	border-top: 3px solid #13456c;
	background-color: hsla(0, 0%, 100%, 0.93);
}

.header-search-form {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.header-mobile-search-reveal {
	display: block;
	overflow: hidden;
	height: 0px;
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

.slider-caption-title-wrap {
	margin-bottom: 0.5rem;
}

.mobile-menu-styles-block {
	position: relative;
	overflow: hidden;
	background-color: #21272e;
}

.nav-search-button-icon {
	width: 1.125em;
	height: 1.375em;
}

.nav-resize-trigger {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	height: 80px;
}

.header-wrap {
	position: relative;
	width: 100%;
}

.slider-image-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-image: url('/20150114134215/assets/images/translucent-fibres.png');
	background-position: 0px 0px;
	opacity: 0.4;
}

.nav-search-button-close-icon {
	display: none;
	width: 1em;
	height: 1.375em;
}

.footer-social-icons-links-wrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-flex-wrap: wrap;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-flex-basis: 100%;
	-ms-flex-preferred-size: 100%;
	flex-basis: 100%;
}

.footer-signoff-back-top-button {
	margin-left: 1.5rem;
	padding: 0.75em 2.5em 0.75em 1em;
	background-image: url('/20150114134215/assets/images/Arrow2_up_blk.svg');
	background-position: 75% 50%;
	background-size: 7px 5px;
	background-repeat: no-repeat;
	box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.1);
	opacity: 0.55;
	color: #000;
	font-size: 0.75rem;
	line-height: 1.25em;
	font-weight: 500;
	text-transform: uppercase;
}

.footer-signoff-back-top-button:hover {
	box-shadow: inset 0 0 0 1px #8ba8b6;
	opacity: 1;
}

.texture-wrap {
	height: 30px;
	margin-bottom: 1.5rem;
	background-image: url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 50% 0px;
	opacity: 0.25;
}

.texture-wrap.cbox-image-link-overlay {
	position: absolute;
	left: 7%;
	top: -5px;
	right: auto;
	bottom: auto;
	width: 33%;
	height: 18px;
	margin-bottom: 0rem;
	background-image: url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 50% 0px;
	opacity: 1;
}

.texture-wrap.slider-caption-texture {
	position: absolute;
	left: 25px;
	top: -5px;
	right: 50%;
	height: 20px;
	margin-bottom: 0rem;
	background-image: url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 50% 0px;
	opacity: 1;
}

.texture-wrap.inside-page-header-texture-accent {
	position: absolute;
	top: -3vw;
	width: 20%;
	margin-top: -22px;
	margin-bottom: 0rem;
	background-image: url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 50% 0px;
	opacity: 1;
}

.texture-wrap.portfolio-slider-caption-texture {
	position: absolute;
	top: 0px;
	width: 100px;
	height: 15px;
	margin-top: -10px;
	margin-bottom: 0rem;
	background-image: url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 50% 0px;
}

.nav-facebook-icon {
	width: 1.375em;
	height: 1.375em;
	padding: 4px 0px 0px 3px;
	border: 1px solid #335977;
	border-radius: 1px;
	opacity: 1;
}

.footer-partner-link {
	-webkit-filter: grayscale(35%);
	filter: grayscale(35%);
}

.footer-partner-link:hover {
	-webkit-filter: grayscale(0%);
	filter: grayscale(0%);
}

.footer-partner-link-image {
	width: 130px;
}

.footer-partner-link-image.muskita {
	width: 110px;
}

.partners-wrapper .footer-partner-link {
	margin-right: 1.5rem;
}

.home-image-link-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	padding-right: 0.75em;
	padding-bottom: 1.5em;
	padding-left: 0.75em;
	-webkit-box-pack: end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-image: url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 0px 0px;
	opacity: 0.4;
	-webkit-transition: all 700ms ease;
	transition: all 700ms ease;
}

.intro-content-box {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-webkit-flex-direction: row;
	-ms-flex-direction: row;
	flex-direction: row;
	-webkit-box-pack: start;
	-webkit-justify-content: flex-start;
	-ms-flex-pack: start;
	justify-content: flex-start;
}

.home-image-link-overlay-arrow {
	position: relative;
	display: none;
	width: 1em;
	height: 1em;
	padding: 4px;
	background-image: url('/20150114134215/assets/images/Arrow3_right_blk.svg');
	background-position: 50% 50%;
	background-size: 65% 65%;
	background-repeat: no-repeat;
	opacity: 0.5;
}

.home-image-link-text {
	position: relative;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding: 0.75em 14% 0.75em 7%;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	-webkit-box-flex: 1;
	-webkit-flex-grow: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	background-color: rgba(9, 49, 76, 0);
	background-image: -webkit-linear-gradient(90deg, rgba(9, 49, 76, 0.65), rgba(9, 49, 76, 0) 50%);
	background-image: linear-gradient(0deg, rgba(9, 49, 76, 0.65), rgba(9, 49, 76, 0) 50%);
	-webkit-transition: all 700ms ease;
	transition: all 700ms ease;
}

.home-image-link-text:hover {
	padding-bottom: 1.5em;
	background-color: rgba(9, 49, 76, 0.65);
}

.portfolio-slider-wrap {
	height: 32vw;
	max-height: 600px;
	margin-bottom: 1.5em;
	background: #ffffff;
}

.portfolio-mask {
	overflow: visible;
}

.portfolio-slide {
	position: relative;
	background-image: url('/20150114134215/assets/images/slide-16.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.portfolio-slide.portfolio-slide-2 {
	background-image: url('/20150114134215/assets/images/slide-18.jpg');
}

.portfolio-slide.portfolio-slide-3 {
	background-image: url('/20150114134215/assets/images/slide-19.jpg');
}

.portfolio-slide.portfolio-slide-4 {
	background-image: url('/20150114134215/assets/images/slide-20.jpg');
}

.portfolio-slider-arrow {
	left: auto;
	top: auto;
	right: 1rem;
	bottom: 1rem;
	width: 2.5rem;
	height: 2.25rem;
	background-color: #09314c;
	opacity: 0.75;
	-webkit-transition: all 500ms ease;
	transition: all 500ms ease;
	font-size: 0.875rem;
}

.portfolio-slider-arrow:hover {
	opacity: 1;
}

.portfolio-slider-arrow.left-arrow {
	margin-right: 2.75rem;
}

.portfolio-slider-nav {
	left: auto;
	right: 7.5rem;
	bottom: 1.8125rem;
	height: 0.5rem;
	padding-top: 0px;
	font-size: 0.5rem;
	line-height: 1em;
}

.portfolio-cat-tile-link {
	position: relative;
	height: 18vw;
	max-height: 400px;
	min-height: 200px;
	margin-right: 2%;
	margin-bottom: 1.5vw;
	-webkit-flex-basis: 31.33%;
	-ms-flex-preferred-size: 31.33%;
	flex-basis: 31.33%;
	background-image: url('/20150114134215/assets/images/slide-05.jpg');
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
	font-family: Rajdhani, sans-serif;
	color: #fff;
	font-size: 1.625rem;
	line-height: 1em;
	font-weight: 500;
	text-shadow: 0 2px 3px rgba(5, 34, 53, 0.3);
}

.portfolio-cat-tile-link:hover {
	color: #fff;
}

.portfolio-cat-tile-link.cat-tile-2 {
	background-image: url('/20150114134215/assets/images/slide-03-2.jpg');
}

.portfolio-cat-tile-link.cat-tile-3 {
	background-image: url('/20150114134215/assets/images/slide-01.jpg');
}

.portfolio-cat-tile-link.cat-tile-4 {
	background-image: url('/20150114134215/assets/images/slide-19.jpg');
}

.portfolio-cat-tile-link.cat-tile-5 {
	background-image: url('/20150114134215/assets/images/slide-07-1.jpg');
}

.portfolio-cat-tile-overlay {
	position: absolute;
	left: 0px;
	top: 0px;
	right: 0px;
	bottom: 0px;
	background-image: url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 50% 50%;
	opacity: 0.4;
}

.portfolio-cat-tile-title {
	position: absolute;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	overflow: hidden;
	width: 100%;
	height: 100%;
	padding: 1.5vw 2vw 1.5vw 1.5vw;
	-webkit-box-align: end;
	-webkit-align-items: flex-end;
	-ms-flex-align: end;
	align-items: flex-end;
	background-image: -webkit-linear-gradient(90deg, rgba(9, 49, 76, 0.65), rgba(9, 49, 76, 0) 50%);
	background-image: linear-gradient(0deg, rgba(9, 49, 76, 0.65), rgba(9, 49, 76, 0) 50%);
	-webkit-transition: all 700ms ease;
	transition: all 700ms ease;
	color: #fff;
}

.portfolio-cat-tile-title:hover {
	padding-bottom: 4vw;
	background-color: rgba(9, 49, 76, 0.65);
}

.portfolio-slider-caption-wrap {
	position: absolute;
	left: 0px;
	right: auto;
	bottom: 1rem;
	max-width: 400px;
	padding: 1em 2em 1em 1em;
	background-color: #09314c;
	color: #fff;
	font-size: 0.75rem;
	line-height: 1.25em;
	min-width: 200px;
	word-wrap: break-word;
}

.inside-floated-image-example {
	width: 370px;
	margin-bottom: 1.5em;
	margin-left: 5%;
	float: right;
}

.project-section-title {
	margin-top: 0em;
}

.footer-contact-label-span {
	display: inline-block;
	min-width: 40px;
}

html.w-mod-js *[data-ix="md-patient-comments-reveal-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="for-two-part-button-list"] {
	-webkit-transform: translate(100%, 0px);
	-ms-transform: translate(100%, 0px);
	transform: translate(100%, 0px);
}

html.w-mod-js *[data-ix="display-none-on-page-load"] {
	display: none;
}

html.w-mod-js *[data-ix="for-grid-view-button"] {
	opacity: 0.7500000000000003;
}

html.w-mod-js *[data-ix="preloader-wrap"] {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

html.w-mod-js *[data-ix="hide-mobile-header-on-load"] {
	height: 0px;
}

html.w-mod-js *[data-ix="home-scroll-animation"] {
	opacity: 0;
}

@media (min-width: 767px) {
	.footer-column-wrap.partners {
		padding-left: 20px;
	}
}

@media (max-width: 991px) {
	h1 {
		font-size: 1.85em;
	}

	.button.header-search-button {
		position: absolute;
		right: 0px;
		width: 4rem;
		height: 4rem;
		font-size: 1rem;
	}

	.container.home-cbox-container {
		padding: 40px 20px;
	}

	.container.home-cbox-container.home-intro-news-container {
		padding-bottom: 60px;
	}

	.container.footer-container {
		padding-right: 20px;
		padding-left: 20px;
	}

	.container.inside-container {
		padding: 25px 20px 40px;
	}

	.nav-container {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: 100%;
		margin-right: auto;
		margin-left: auto;
		padding-left: 15px;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.nav-link {
		z-index: 140;
		display: block;
		width: 100%;
		margin-right: 0px;
		margin-bottom: 1px;
		padding: 1.25em 1.375rem;
		float: left;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #fff;
		font-size: 1.125rem;
		font-weight: 400;
		text-align: left;
		letter-spacing: 0.02em;
		text-transform: none;
	}

	.nav-link:hover {
		background-color: transparent;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
		color: #8d999f;
	}

	.nav-link.w--current {
		background-color: transparent;
		background-image: none;
		background-size: auto;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), inset 5px 0 0 0 #fff;
		color: #8d999f;
	}

	.nav-link.two-part-mobile {
		width: 80%;
		float: left;
	}

	.nav-link.mobile-list-back {
		background-color: rgba(0, 0, 0, 0.25);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		color: #8d999f;
		cursor: pointer;
	}

	.nav-link.mobile-list-back:hover {
		color: #fff;
	}

	.nav-link.secondary-nav-button {
		height: 100%;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		opacity: 1;
		font-size: 1rem;
	}

	.nav-link.secondary-nav-button:hover {
		opacity: 0.5;
	}

	.nav-menu-wrap {
		display: block;
		max-width: 100%;
		margin-top: 0px;
		padding-bottom: 100vh;
		border-top: 1px none #21272e;
		border-left-style: none;
		background-color: #21272e;
		background-image: -webkit-linear-gradient(270deg, rgba(33, 39, 46, 0.85), rgba(33, 39, 46, 0.85)), url('/20150114134215/assets/images/diagonal-noise-1.png');
		background-image: linear-gradient(180deg, rgba(33, 39, 46, 0.85), rgba(33, 39, 46, 0.85)), url('/20150114134215/assets/images/diagonal-noise-1.png');
		background-position: 0px 0px, 50% 0px;
		text-align: left;
	}

	.logo {
		margin-right: 0px;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		font-size: 1.375rem;
	}

	.row.footer-signoff-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.home-cbox-row {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.column.footer-signoff-right-col {
		margin-top: 1.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.column.footer-signoff-left-column {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-bottom: 0.5rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}

	.menu-button {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		overflow: hidden;
		height: 100%;
		padding: 0px 1.25rem 0px 0.75rem;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		background-color: transparent;
		box-shadow: none;
		-webkit-transition: all 250ms ease;
		transition: all 250ms ease;
		color: #13456c;
		line-height: 3.5rem;
		font-weight: 500;
		text-align: center;
		text-transform: uppercase;
	}

	.menu-button:hover {
		color: rgba(9, 49, 76, 0.5);
	}

	.menu-button.w--open {
		background-color: #3d444d;
		background-image: -webkit-linear-gradient(270deg, rgba(61, 68, 77, 0.8), rgba(61, 68, 77, 0.8)), url('/20150114134215/assets/images/diagonal-noise-1.png');
		background-image: linear-gradient(180deg, rgba(61, 68, 77, 0.8), rgba(61, 68, 77, 0.8)), url('/20150114134215/assets/images/diagonal-noise-1.png');
		background-position: 0px 0px, 50% 50%;
		color: #fff;
	}

	.menu-button.w--open:hover {
		color: #8d999f;
	}

	.footer-signoff-section {
		text-align: center;
	}

	.copyright-text {
		margin-left: 0rem;
	}

	.footer-signoff-list {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.footer-signoff-grip {
		margin-left: 0em;
		float: none;
	}

	.header-content-right-wrap {
		height: 100%;
		padding-bottom: 0rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.two-part-button-wrap {
		position: static;
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		width: 100%;
		max-width: none;
		float: left;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.form-field.header-search-field {
		width: 70%;
		height: 4rem;
		padding-right: 5rem;
		padding-left: 1.25rem;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		border-style: none;
		font-size: 1rem;
	}

	.secondary-nav-list {
		height: 100%;
		margin-top: 0rem;
		margin-right: 0px;
		margin-bottom: 0em;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-nav-list-item {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		padding: 0rem 0px;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.right-sidebar-column {
		margin-top: 3rem;
		padding-top: 0vw;
	}

	.left-nav-list {
		margin-bottom: 1.5em;
	}

	.sidebar-section-wrap {
		margin-left: 0em;
	}

	.secondary-nav-icon {
		width: 0.875rem;
		height: 0.875rem;
	}

	.secondary-nav-list-link {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		margin-right: 0rem;
		margin-left: 0rem;
		padding-right: 0rem;
		padding-left: 0rem;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		font-size: 0.6875rem;
		font-weight: 500;
	}

	.slider-arrow {
		width: 3rem;
	}

	.slider-arrow.left-slider-arrow {
		margin-right: 3.5rem;
	}

	.slider-indicator {
		margin-right: 7rem;
	}

	.home-image-link {
		max-height: 180px;
		margin-right: 0%;
	}

	.home-image-link.large-image-link {
		height: 25vw;
		margin-right: 0%;
	}

	.home-image-link.intro-cbox-image-link {
		height: 50vw;
		min-height: 300px;
		margin-right: 0%;
	}

	.home-content-box-wrap {
		margin-bottom: 1.5em;
		padding-bottom: 1.5em;
	}

	.home-content-box-wrap.large-content-box-wrap {
		margin-top: 0.75em;
		margin-bottom: 0.75em;
		padding-bottom: 0em;
	}

	.home-content-box-title {
		font-size: 1.4125em;
	}

	.slider-caption-wrap {
		left: 0px;
		max-width: 440px;
		padding-right: 50px;
		padding-left: 20px;
		font-size: 1rem;
	}

	.inside-page-header-section {
		min-height: 200px;
	}

	.main-content-wrap {
		padding-top: 0vw;
	}

	.main-content-wrap.nested-content-wrap {
		margin-right: 0em;
	}

	.menu-button-icon {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		padding-top: 1px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-align-self: center;
		-ms-flex-item-align: center;
		align-self: center;
		font-size: 2rem;
		line-height: 3.5rem;
	}

	.menu-button-text {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		margin-left: 0.125rem;
		padding-top: 1px;
		float: left;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		font-size: 0.75rem;
		line-height: 3.5rem;
		letter-spacing: -0.02em;
	}

	.header-menu-inside-wrap {
		max-width: 100%;
		padding-right: 0vw;
		padding-left: 0vw;
	}

	.intro-text-style {
		font-size: 1.1875em;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		padding-top: 0.5rem;
	}

	.left-nav-list-content-wrap {
		overflow: hidden;
		height: 0px;
	}

	.loc-sidebar-form-reveal {
		height: 0px;
	}

	.inside-page-content-wrap-no-sidebars {
		margin-right: 0%;
		margin-left: 0%;
	}

	.nav-drop-wrap {
		width: 100%;
	}

	.nav-drop-toggle {
		display: block;
	}

	.nav-drop-toggle-link {
		display: block;
		padding-left: 1rem;
		text-align: left;
	}

	.two-part-button-toggle {
		width: 20%;
		margin-bottom: 1px;
		padding-top: 1.25em;
		padding-bottom: 1.25em;
		float: right;
		-webkit-flex-shrink: 0;
		-ms-flex-negative: 0;
		flex-shrink: 0;
		background-color: rgba(0, 0, 0, 0.25);
		background-image: url('/20150114134215/assets/images/icon_arrow2_right_wht_50.svg');
		background-position: 50% 50%;
		background-size: 1em 1em;
		background-repeat: no-repeat;
		box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25), -1px 0 0 0 hsla(0, 0%, 100%, 0.25);
		-webkit-transition: all 350ms ease;
		transition: all 350ms ease;
		color: transparent;
		font-size: 0.875rem;
		line-height: 2rem;
		text-align: center;
		cursor: pointer;
	}

	.two-part-button-toggle:hover {
		background-color: rgba(0, 0, 0, 0.15);
	}

	.two-part-button-list {
		position: absolute;
		left: 0px;
		top: 0px;
		z-index: 150;
		display: block;
		width: 100%;
		margin-left: 100%;
		padding-bottom: 100vh;
		border-top: 1px none #21272e;
		background-color: #21272e;
		background-image: -webkit-linear-gradient(270deg, rgba(33, 39, 46, 0.85), rgba(33, 39, 46, 0.85)), url('/20150114134215/assets/images/diagonal-noise-1.png');
		background-image: linear-gradient(180deg, rgba(33, 39, 46, 0.85), rgba(33, 39, 46, 0.85)), url('/20150114134215/assets/images/diagonal-noise-1.png');
		background-position: 0px 0px, 50% 0px;
	}

	.two-part-drop-list-back-arrow {
		display: inline-block;
		min-width: 0.75em;
		margin-top: -1px;
		margin-right: 0.25em;
		float: left;
		background-image: url('/20150114134215/assets/images/icon_arrow2_left_wht_50.svg');
		background-position: 0px 55%;
		background-size: 12px 12px;
		background-repeat: no-repeat;
		color: transparent;
		text-align: center;
	}

	.two-part-drop-list-section {
		padding: 2em 1rem 1em;
		border-bottom: 1px solid #fff;
		color: hsla(0, 0%, 100%, 0.5);
		font-size: 0.875rem;
		line-height: 1.25em;
		font-weight: 600;
		letter-spacing: 0.05em;
		text-transform: uppercase;
	}

	.header-container {
		padding-right: 0px;
		padding-left: 0px;
	}

	.secondary-header-item-wrap {
		height: 100%;
		margin-right: 0rem;
		margin-left: 0rem;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		color: #000;
		font-size: 0.875rem;
	}

	.header-content-right-row {
		height: 100%;
		margin-top: 0rem;
		margin-bottom: 0rem;
		-webkit-box-pack: justify;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.secondary-header-item-cta-link {
		margin-left: 1.5rem;
	}

	.header-search-wrap {
		margin-left: 0rem;
		padding: 0rem;
		float: none;
		border-top-width: 1px;
		border-top-color: rgba(0, 0, 0, 0.05);
		box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.05);
	}

	.header-search-form {
		position: relative;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.header-mobile-search-reveal {
		overflow: hidden;
	}

	.mobile-menu-content-wrap {
		display: -webkit-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		height: 100%;
		-webkit-box-pack: end;
		-webkit-justify-content: flex-end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-webkit-box-ordinal-group: 2;
		-webkit-order: 1;
		-ms-flex-order: 1;
		order: 1;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.nav-resize-trigger {
		height: 40px;
	}

	.footer-social-icons-links-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: flex-start;
		-ms-flex-line-pack: start;
		align-content: flex-start;
	}

	.footer-signoff-back-top-button {
		margin-left: 0rem;
		font-size: 0.75rem;
	}

	.texture-wrap.slider-caption-texture {
		left: 20px;
	}

	.texture-wrap.inside-page-header-texture-accent {
		top: -25px;
		height: 25px;
		margin-top: -20px;
	}

	.nav-facebook-icon {
		width: 1.3125em;
		height: 1.3125em;
		padding-top: 3px;
		padding-left: 2px;
	}

	.intro-content-box {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.home-image-link-text {
		padding-left: 5%;
	}

	.portfolio-slider-wrap {
		height: 58vw;
		max-height: none;
	}

	.portfolio-cat-tile-link {
		height: 32vw;
		margin-right: 3%;
		margin-bottom: 3vw;
		-webkit-flex-basis: 47%;
		-ms-flex-preferred-size: 47%;
		flex-basis: 47%;
	}

	.portfolio-cat-tile-title {
		padding-right: 5vw;
		padding-bottom: 2.5vw;
		padding-left: 2.5vw;
	}

	.portfolio-cat-tile-title:hover {
		padding-bottom: 5vw;
	}
}

@media (max-width: 767px) {
	body {
		font-size: 1rem;
	}

	h1 {
		font-size: 1.65em;
	}

	h2 {
		font-size: 1.5em;
	}

	h3 {
		font-size: 1.375em;
	}

	h4 {
		font-size: 1.125em;
	}

	.container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.home-cbox-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.home-cbox-container.home-intro-news-container {
		padding-top: 2rem;
	}

	.container.footer-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.container.inside-container {
		padding-right: 10px;
		padding-left: 10px;
	}

	.nav-container {
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	.nav-link {
		font-size: 1rem;
	}

	.nav-link.secondary-nav-button {
		max-width: 4rem;
		min-width: 1.75rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.logo {
		width: 190px;
		padding-left: 0px;
	}

	.row.footer-row {
		margin-right: 10px;
		margin-left: 10px;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.row.portfolio-category-row {
		margin-right: 0%;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 0.625rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.footer-column-wrap {
		min-width: 35%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
		text-align: center;
	}

	.breadcrumb-list {
		font-size: 0.6875rem;
	}

	.secondary-nav-list {
		margin-right: 0px;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.secondary-nav-list-item {
		max-width: 100%;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.right-sidebar-column {
		margin-top: 1.5rem;
		padding-top: 1.5rem;
	}

	.sidebar-section-wrap {
		margin-left: 0rem;
	}

	.secondary-nav-icon {
		margin: 0.25em 0.5em;
	}

	.secondary-nav-icon.tel-icon {
		width: 1.25rem;
		height: 1.25rem;
		margin-right: 0.25rem;
		margin-bottom: 0rem;
	}

	.secondary-nav-list-link {
		padding-right: 0.5em;
		padding-left: 0.5em;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-align-content: center;
		-ms-flex-line-pack: center;
		align-content: center;
		-webkit-box-flex: 1;
		-webkit-flex: auto;
		-ms-flex: auto;
		flex: auto;
	}

	.slider {
		height: auto;
		background-color: #09314c;
	}

	.slider-arrow {
		left: 20px;
		top: 90vh;
		right: auto;
		bottom: auto;
		width: 2rem;
		height: 2rem;
		margin-top: -3rem;
		margin-left: 2.5rem;
	}

	.slider-arrow.left-slider-arrow {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.slider-indicator {
		top: 90vh;
		right: 5px;
		margin-top: -3.25rem;
		margin-right: 0rem;
		padding-right: 20px;
		background-image: none;
	}

	.slider-indicator:hover {
		background-color: transparent;
	}

	.home-image-link {
		max-height: 140px;
	}

	.home-image-link.large-image-link {
		width: 100%;
		height: 30vw;
		max-height: 260px;
		min-height: 150px;
		font-size: 1.5rem;
	}

	.home-image-link.intro-cbox-image-link {
		min-height: 260px;
		margin-right: 0%;
	}

	.home-content-box-wrap.large-content-box-wrap {
		border-bottom: 1px none rgba(0, 0, 0, 0.1);
	}

	.home-content-box-wrap.large-content-box-wrap.last-large-cbox {
		border-bottom-style: none;
	}

	.home-content-box-title.intro-cbox-title {
		font-size: 1.75em;
	}

	.slider-image {
		position: relative;
		height: 90vh;
	}

	.slider-caption-wrap {
		position: relative;
		left: 0%;
		width: 100%;
		max-width: 100%;
		min-width: 0px;
		margin-right: 0%;
		margin-left: 0%;
		padding: 30px 20px 20px;
		background-color: #09314c;
		font-size: 1rem;
	}

	.inside-page-header-content-wrap {
		margin-right: 10px;
		margin-left: 10px;
	}

	.main-content-wrap {
		overflow: visible;
		padding-top: 0vw;
	}

	.main-content-wrap.no-sidebars {
		margin-right: 10px;
		margin-left: 10px;
	}

	.home-cbox-text-wrap.large-cbox-text-wrap {
		width: 60%;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-align: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-flex-basis: 60%;
		-ms-flex-preferred-size: 60%;
		flex-basis: 60%;
	}

	.home-cbox-text-wrap.intro-cbox-text-wrap {
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.slider-caption-title {
		font-size: 1.75em;
	}

	.two-part-button-toggle {
		line-height: 1.6875rem;
	}

	.secondary-header-item-wrap {
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		font-size: 0.75rem;
	}

	.secondary-header-item-cta-link {
		margin-right: 0rem;
		margin-left: 1.5rem;
		padding-top: 0.75em;
		padding-right: 2.5em;
		padding-left: 1.5em;
		background-position: 90% 50%;
		background-size: 8px 8px;
		font-size: 0.6875rem;
		line-height: 1rem;
	}

	.slider-caption-title-wrap {
		text-shadow: none;
	}

	.nav-resize-trigger {
		height: 20px;
	}

	.footer-signoff-back-top-button {
		padding-top: 1em;
		padding-bottom: 1em;
		font-size: 0.625rem;
		line-height: 1.5em;
	}

	.texture-wrap.cbox-image-link-overlay {
		min-width: 70px;
	}

	.texture-wrap.slider-caption-texture {
		top: 0px;
		height: 10px;
	}

	.texture-wrap.inside-page-header-texture-accent {
		height: 20px;
		min-width: 120px;
		margin-top: -15px;
	}

	.nav-facebook-icon {
		width: 1.1875em;
		height: 1.1875em;
	}

	.intro-content-box {
		margin-right: 10px;
		margin-left: 10px;
	}

	.portfolio-slider-wrap {
		top: -1px;
		height: 60vw;
		margin-top: -1.5em;
		margin-right: -20px;
		margin-left: -20px;
	}

	.portfolio-slider-arrow {
		left: 20px;
		right: auto;
		bottom: 0.75rem;
		width: 2rem;
		height: 2rem;
		margin-left: 2.25rem;
	}

	.portfolio-slider-arrow.left-arrow {
		margin-right: 0rem;
		margin-left: 0rem;
	}

	.portfolio-slider-nav {
		right: 20px;
		bottom: 1.375rem;
	}

	.portfolio-cat-tile-link {
		height: 50vw;
		min-height: 260px;
		margin-right: 0%;
		margin-bottom: 20px;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
		font-size: 1.75rem;
	}

	.portfolio-cat-tile-title {
		padding-right: 7vw;
		padding-bottom: 3.5vw;
		padding-left: 3.5vw;
	}

	.portfolio-cat-tile-title:hover {
		padding-bottom: 10vw;
	}

	.portfolio-slider-caption-wrap {
		top: 100%;
		bottom: auto;
		display: none;
	}

	.inside-floated-image-example {
		width: 100%;
		margin-bottom: 1.5em;
		margin-left: 0%;
		float: left;
	}
}

@media (max-width: 479px) {
	h1 {
		font-size: 1.525em;
	}

	h3 {
		font-size: 1.3125em;
	}

	.button.slider-button {
		font-size: 0.75rem;
	}

	.nav-container {
		padding-left: 13px;
	}

	.nav-link {
		font-size: 0.9375rem;
	}

	.nav-link.secondary-nav-button {
		min-width: 1rem;
	}

	.logo {
		width: 150px;
		margin-right: 0px;
	}

	.footer-section {
		text-align: center;
	}

	.column.footer-signoff-right-col {
		margin-top: 0rem;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: nowrap;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
	}

	.column.footer-signoff-left-column {
		-webkit-justify-content: space-around;
		-ms-flex-pack: distribute;
		justify-content: space-around;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
	}

	.menu-button {
		padding-right: 1rem;
		padding-left: 1rem;
		-webkit-align-self: stretch;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	.footer-signoff-list {
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	.footer-signoff-grip {
		margin: 1rem;
	}

	.footer-column-wrap {
		min-width: 50%;
		width: 100%;
		padding-right: 0em;
		-webkit-flex-basis: auto;
		-ms-flex-preferred-size: auto;
		flex-basis: auto;
	}

	.header-content-right-wrap {
		margin-right: 0em;
		-webkit-box-align: center;
		-webkit-align-items: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}

	.form-field.header-search-field {
		font-size: 0.875rem;
	}

	.secondary-nav-list {
		max-width: 100%;
		-webkit-flex-basis: 50%;
		-ms-flex-preferred-size: 50%;
		flex-basis: 50%;
	}

	.secondary-nav-list-item {
		max-width: 100%;
	}

	.secondary-nav-list-link {
		padding-right: 0.5em;
		padding-left: 0.25em;
	}

	.home-image-link {
		height: 25vw;
		min-height: 100px;
		min-width: 35%;
		margin-right: 5%;
		float: none;
	}

	.home-image-link.large-image-link {
		max-height: 180px;
		min-height: 180px;
		margin-right: 0%;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.home-image-link.intro-cbox-image-link {
		min-height: 160px;
	}

	.home-content-box-wrap.large-content-box-wrap {
		margin-top: 1em;
		margin-bottom: 1em;
		-webkit-flex-wrap: wrap;
		-ms-flex-wrap: wrap;
		flex-wrap: wrap;
	}

	.home-content-box-title.intro-cbox-title {
		font-size: 1.625em;
	}

	.menu-button-text {
		display: none;
	}

	.home-cbox-text-wrap {
		width: 100%;
		float: none;
	}

	.home-cbox-text-wrap.large-cbox-text-wrap {
		width: 100%;
		padding-top: 0.5rem;
		-webkit-flex-basis: 100%;
		-ms-flex-preferred-size: 100%;
		flex-basis: 100%;
	}

	.slider-caption-title {
		font-size: 1.625em;
	}

	.footer-social-icons-section-wrap {
		width: 100%;
		padding-right: 0px;
		float: none;
	}

	.two-part-button-toggle {
		font-size: 0.8125rem;
	}

	.secondary-header-item-wrap {
		display: none;
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
		-webkit-box-flex: 1;
		-webkit-flex-grow: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
	}

	.footer-social-icons-links-wrap {
		-webkit-box-pack: center;
		-webkit-justify-content: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.footer-signoff-back-top-button {
		margin: 1em 1rem;
	}

	.portfolio-slider-wrap {
		height: 59vw;
	}

	.portfolio-cat-tile-link {
		height: 60vw;
		min-height: 180px;
		font-size: 1.4375rem;
	}

	.portfolio-cat-tile-title {
		padding-bottom: 5vw;
	}

	.partners-wrapper .footer-partner-link {
		margin-bottom: 1.5rem;
	}
}

@media ( max-width: 465px) {
	.partners-wrapper {
		display: flex;
		flex-direction: column;
	}

	.partners-wrapper .footer-partner-link {
		margin-right: 0;
	}
}


/****************** Tablesaw ***********************/
/****************************************************/
@media (max-width: 39.9375em) {
	.tablesaw.styled tr {
		border-bottom: 2px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr td {
		border-right: 0px solid;
		border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	}

	.tablesaw.styled tr:last-child,
	.tablesaw.styled tr:last-child td:last-child {
		border-bottom: 0px solid;
	}
}

/****************** Breakpoints ********************/
/****************************************************/
[data-breakpoints] {
	display: none !important;
}

@media screen and ( min-width: 991px ) {
	[data-breakpoints*="xl"] {
		display: block !important;
	}
}

@media screen and ( min-width: 767px ) {
	[data-breakpoints*="md,lg"] {
		display: block !important;
	}
}

[data-breakpoints*="xs,sm"] {
	display: block !important;
}

/****************** Print Styles *****************/
/*************************************************/
#printFooter {
	display: none;
	visibility: hidden;
}

#printHeader {
	display: none;
	visibility: hidden;
}

/****************** Webflow Styles *****************/
/*************************************************/
html {
	font-size: 16px;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.footer-section a[href^=tel] {
	-webkit-transition: all 250ms ease;
	color: #13456c;
	text-decoration: none;
}

input[type=text] {
	-webkit-appearance: none;
	-moz-appearance: none;
}

input::-webkit-input-placeholder {
	color: #999 !important;
}

input::-moz-placeholder {
	color: #999 !important;
}

input:-moz-placeholder {
	color: #999 !important;
}

input:-ms-input-placeholder {
	color: #999 !important;
}

.blockstyle-heading {
	-webkit-box-decoration-break: clone;
}

/****************** Header ***********************/
/*************************************************/
.nav-drop-toggle-link.selected {
	background-position: 50% 104%;
}

.nav-drop-list {
	display: block;
	opacity: 0;
	height: 0;
}

.facebook-link.w--current {
	border-bottom: 0px;
	box-shadow: none;
	background-position: 50% 125%;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	html.mm-opened .nav-section {
		position: static;
	}

	html.mm-opened .nav-section.sticky {
		display: none;
	}
}


/*************** Mobile navigation ***************/
/*************************************************/
@media (min-width: 992px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-opened {
		display: none !important;
	}
}

@media (max-width: 991px) {
	.mobile-navigation-menu {
		display: none !important;
	}

	.mobile-navigation-menu.mm-opened {
		display: block !important;
	}
}

.mobile-navigation-menu {
	background-color: #21272e;
	background-image: -webkit-linear-gradient(270deg, rgba(33, 39, 46, 0.85), rgba(33, 39, 46, 0.85)), url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-image: linear-gradient(180deg, rgba(33, 39, 46, 0.85), rgba(33, 39, 46, 0.85)), url('/20150114134215/assets/images/diagonal-noise-1.png');
	background-position: 0px 0px, 50% 0px;
}

.mobile-navigation-menu li {
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
}

.mobile-navigation-menu li a.nav-link-a {
	line-height: 1.375rem;
	font-weight: 400;
	text-align: left;
	letter-spacing: 0.02em;
	text-transform: none;
	margin-bottom: 1px;
	padding: 1.25em 1.375rem;
	background-color: transparent;
	background-image: none;
	background-size: auto;
	color: #fff;
	font-family: Montserrat, sans-serif;
	font-size: 1rem;
}

.mobile-navigation-menu li a.nav-link-a:hover {
	color: #677179;
}

.mobile-navigation-menu li.selected a.nav-link-a {
	background-color: transparent;
	background-image: none;
	background-size: auto;
	box-shadow: inset 5px 0 0 0 #fff;
	color: #8d999f;
}

.mm-menu .mm-listview > li.mm-selected > a:not(.mm-next),
.mm-menu .mm-listview > li.mm-selected > span {
	background: none;
}

.mm-listview .mm-next {
	background: rgba(0, 0, 0, 0.25);
	width: 80px;
}

.mm-listview .mm-next + a,
.mm-listview .mm-next + span {
	margin-right: 80px;
}

.mm-listview > li .mm-next::before {
	border-color: hsla(0, 0%, 100%, 0.25);
}

.mm-menu .mm-listview > li .mm-arrow::after,
.mm-menu .mm-listview > li .mm-next::after {
	border-color: #7a7d80;
}

.mm-listview > li .mm-next:hover {
	background-color: rgba(0, 0, 0, 0.15);
}

.mm-navbar {
	background-color: rgba(0, 0, 0, 0.25);
	box-shadow: 0 1px 0 0 hsla(0, 0%, 100%, 0.25);
	text-align: left;
	height: 60px;
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar > * {
	color: #8d999f;
	font-size: 1rem;
	font-weight: 400;
	letter-spacing: 0.02em;
	padding: 19px 0;
}

.mm-menu .mm-navbar a:hover {
	color: #fff;
}

.mm-panels > .mm-panel > .mm-navbar + .mm-listview {
	margin-top: 0px;
}

.mm-menu .mm-btn::after,
.mm-menu .mm-btn::before {
	border-color: #8d999f;
	top: 17px;
}

.mm-prev::before {
	left: 25px;
}

.mm-arrow::after,
.mm-next::after {
	right: 35px;
}

.mm-opened .menu-button {
	background-color: #3d444d;
	background-image: -webkit-linear-gradient(270deg, rgba(61, 68, 77, 0.8), rgba(61, 68, 77, 0.8)), url("http://uploads.webflow.com/5914a8885dddb34a99c225ee/591b54b123cdc2658e4c117a_diagonal-noise%20(1).png");
	background-image: linear-gradient(180deg, rgba(61, 68, 77, 0.8), rgba(61, 68, 77, 0.8)), url("http://uploads.webflow.com/5914a8885dddb34a99c225ee/591b54b123cdc2658e4c117a_diagonal-noise%20(1).png");
	background-position: 0px 0px, 50% 50%;
	color: #fff;
}

/***************** Homepage  **************************/
/******************************************************/
.home-cbox-row:before {
	display: none;
}

.slider {
	background-position: 50% 50%;
	background-size: cover;
	background-repeat: no-repeat;
}

.slider img {
	height: auto;
	max-height: 100%;
	object-fit: cover;
}

.home-content-box-title a,
.home-content-box-title a:hover {
	border-bottom: none;
	font-size: inherit;
	line-height: inherit;
	color: inherit;
	font-family: inherit;
}

/***************** Slideshow  **************************/
/******************************************************/

/*************** Inside page **************************/
/******************************************************/
.inside-content-section .left-nav-list-link,
.inside-content-section .left-nav-nested-list-link {
	border-bottom: 0px;
}

.left-nav-list-link.active {
	background-color: #f4fafc;
}

.left-nav-nested-list-link.active {
	background-color: #f4fafc;
	font-weight: 500;
}

.right-sidebar .main-content-column-nested {
	padding-left: 10px;
	padding-right: 10px;
}

.right-sidebar .right-sidebar-column {
	padding-left: 10px;
	padding-right: 10px;
}

.right-sidebar .main-content-column {
	padding-left: 0px;
	padding-right: 0px;
}

.main-content-column {
	margin-bottom: 40px;
}

.inside-content-section img {
	height: auto;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
}

.inside-row {
	min-height: 500px;
}

@media (max-width: 767px) {
	.mobile-friendly-image {
		display: block;
		float: none !important;
	}
}

/*************** Right Sidebar ********************/
/*************************************************/
.right-sidebar-column .box {
	position: relative;
	margin-bottom: 2.5em;
	margin-left: 3vw;
	padding-top: 0.75em;
	border-top: 3px solid #d5e1e6;
	font-size: 1rem;
	line-height: 1.5em;
}

.right-sidebar-column .box .title h4 {
	margin-top: 0.25em;
	margin-bottom: 0.625em;
	font-size: 1.375em;
	line-height: 1.0625em;
}

.right-sidebar-column .box td {
	vertical-align: top;
	margin-bottom: 0.75em;
}

.right-sidebar-column .box.links .content p {
	margin-bottom: 0.75em;
}

.right-sidebar-column .box.documents .content td {
	padding-bottom: 0.75em;
}

.right-sidebar-column .box.documents .content td:first-child {
	padding-right: 7px;
}

.right-sidebar-column .box .contentbox_item_image {
	width: 24px;
}

.right-sidebar-column .box .contentbox_item_image img {
	max-width: none;
}

.right-sidebar-column .box.partner-document .content td {
	padding-bottom: 0.75em;
}

@media (max-width: 991px) {
	.right-sidebar-column .box {
		margin-left: 0em;
	}
}

/*************** Footer **************************/
/*************************************************/
#GRIPFooterLogo {
	line-height: 11px;
	padding-top: 0px !important;
	float: right;
}

#topLink {
	cursor: pointer;
}

.footer-link.selected {
	border-bottom-color: rgba(9, 49, 76, 0.5);
	color: #09314c;
}

@media (max-width: 991px) {
	.footer-section .phone-number[href^="tel"] {
		display: inline !important;
		color: #677179;
		border-bottom: 0px;
		float: none;
	}
}

@media (max-width: 479px) {
	#GRIPFooterLogo {
		margin: 1rem;
	}
}


/****************** Styles ***********************/
/*************************************************/
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	border-bottom: 1px solid #c5d7df;
	color: #13456c;
}

h1 a:hover,
h2 a:hover,
h3 a:hover,
h4 a:hover,
h5 a:hover,
h6 a:hover {
	border-bottom-style: solid;
	border-bottom-color: #6da7d6;
	color: #09314c;
}

h2 a {
	line-height: 1.4125em;
}

h3 a {
	line-height: 1.4125em;
}

h4 a {
	line-height: 1.5em;
}

h5 a {
	line-height: 1.5625em;
}

h6 a {
	line-height: 1.5625em;
}

.inside-content-section a {
	border-bottom: 1px solid #c5d7df;
}

.inside-content-section a:hover {
	border-bottom-style: solid;
	border-bottom-color: #6da7d6;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button,
.cms_buttons .primary,
.button.primary,
.primary,
.button,
button,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.button.secondary,
.cms_buttons .secondary,
.cms_form_button.secondary,
.secondary,
#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary,
.button.tertiary,
.cms_buttons .tertiary,
.tertiary {
	margin-top: 0.5em;
	margin-bottom: 0.5em;
	padding: 0.5em 1.3em;
	background-color: #13456c;
	-webkit-transition: all 300ms ease;
	transition: all 300ms ease;
	color: #fff;
	font-size: 0.9375rem;
	font-weight: 500;
	text-align: center;
	letter-spacing: 0.02em;
	text-decoration: none;
	text-transform: uppercase;
	text-decoration: none;
	cursor: pointer;
	-webkit-appearance: none;
	border: 0px;
	border-radius: 0px;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.primary:hover,
#submissionforms_module.homepage_contentbox .cms_buttons .cms_form_button:hover,
.cms_buttons .primary:hover,
.button.primary:hover,
.primary:hover,
.button:hover,
button:hover {
	background-color: #09314c;
	color: #fff;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary,
.button.secondary,
.cms_buttons .secondary,
.cms_form_button.secondary,
.secondary {
	background-color: #677179;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.secondary:hover,
.button.secondary:hover,
.cms_buttons .secondary:hover,
.secondary:hover {
	background-color: #09314c;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary,
.button.tertiary,
.cms_buttons .tertiary,
.tertiary {
	background-color: #8d999f;
}

#submissionforms_module.cms_form .cms_buttons .cms_form_button.tertiary:hover,
.button.tertiary:hover,
.cms_buttons .tertiary:hover,
.tertiary:hover {
	background-color: #09314c;
}

#message.success {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #677179;
	color: #fff;
}

#message.error {
	margin-top: 20px;
	margin-bottom: 20px;
	padding: 20px;
	background-color: #c71741;
	color: #fff;
}

#message.success * {
	color: #fff;
}

#message.error * {
	color: #fff;
}

table.styled {
	background-color: #fff;
}

.styled td.tableHeader,
.tableHeader {
	font-weight: 400;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #09314c;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	text-align: left;
}

.styled th {
	font-weight: 300;
	padding: 0.625em 0.625rem;
	background-color: #677179;
	border-bottom: 0px solid white;
	color: white;
	text-align: left;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
}

.styled thead th {
	font-weight: 400;
	text-transform: none;
	letter-spacing: 0.02em;
	color: white;
	background-color: #09314c;
	border-bottom: 1px solid white;
	padding: 0.875em 0.625rem;
	text-align: left;
}

.styled td,
.evenrow td,
.oddrow td {
	padding: 0.375em 0.625rem;
	font-size: 0.9375em;
	line-height: 1.4125em;
	border-right: 1px solid rgba(0, 0, 0, 0.15);
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.styled.striped tr:nth-child(odd) td,
.oddrow td {
	background-color: #ffffff;
}

.evenrow td,
.styled.striped tr:nth-child(even) td {
	background: #f4fafc;
}

.styled.striped td:last-child,
.styled.striped th:last-child,
.oddrow td:last-child,
.evenrow td:last-child,
.styled td:last-child,
.styled th:last-child {
	border-right: 0px;
}

.inside-content-section input[type="text"],
.inside-content-section input[type="tel"],
.inside-content-section input[type="email"],
.inside-content-section input[type="password"],
.inside-content-section select,
.inside-content-section textarea {
	max-width: 100%;
	line-height: 1.42857143;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	padding: 4px 10px;
	font-size: 0.875em;
}

@media (max-width: 991px) {
	.inside-row-nested input[type="text"],
	.inside-row-nested input[type="tel"],
	.inside-row-nested input[type="email"],
	.inside-row-nested input[type="password"],
	.inside-row-nested select,
	.inside-row-nested textarea {
		width: 100%;
	}
}

/****************** Date Picker *******************/
/*************************************************/
#ui-datepicker-div * {
	font-size: 1rem;
}

/****************** Modules ***********************/
/*************************************************/
/* news */
#news_module.cms_list .cms_item_divider,
#news_module.cms_entity .cms_item_divider {
	margin-bottom: 14px;
	margin-top: 28px;
	border-top: 1px solid #d5e1e6;
}

#news_module.cms_entity .cms_date h3 {
	margin-top: 0px;
}

#news_module.cms_list .cms_categories_row .image {
	margin-bottom: 10px;
}

.news_module.cms_category_list .cms_category_header_image {
	margin-bottom: 10px;
}

/* partners */
#partners_module.cms_list .cms_title h3 {
	margin-top: 0px;
}

#partners_module.cms_list .cms_list_item .image {
	padding-right: 15px;
}

#partners_module.cms_list .cms_item_divider {
	margin-bottom: 30px;
	margin-top: 30px;
	border-top: 1px solid #d5e1e6;
}

@media (max-width: 991px) {
	#partners_module.cms_list td {
		display: block;
		width: 100%;
		text-align: left;
		padding-left: 0px;
	}

	#partners_module.cms_list .cms_list_item .image {
		margin-bottom: 10px;
	}

	#partners_module.cms_entity .image {
		float: none;
		display: block;
	}
}

/* photo albums */
#photoalbums_module.cms_list .cms_title h3 {
	margin-top: 0px;
}

#photoalbums_module.cms_list .cms_list_item .cms_image {
	padding-right: 15px;
}

#photoalbums_module.cms_list .cms_item_divider {
	margin-bottom: 30px;
	margin-top: 30px;
	border-top: 1px solid #d5e1e6;
}

#photoalbums_module.cms_entity .cms_footer {
	margin-top: 25px;
}

@media (max-width: 991px) {
	#photoalbums_module.cms_list td {
		display: block;
		width: 100%;
		text-align: left;
		padding-left: 0px;
	}

	#photoalbums_module.cms_list .cms_list_item .cms_image {
		margin-bottom: 10px;
	}
}

/* resources */
#moduleDocuments_module.cms_list .folder h3.cms_title {
	margin-top: 1rem;
}

#moduleDocuments_module.cms_list .cms_description {
	background-position: 4px 8px;
	padding-left: 30px;
	padding-bottom: 15px;
}

#moduleDocuments_module.cms_list .folder .cms_description h4 {
	margin-top: 0px;
}

#moduleDocuments_module.cms_list .cms_description h4 a {
	margin-left: 0px;
}

#moduleDocuments_module.cms_list .cms_description .description {
	margin-left: -25px !important;
}


#moduleDocuments_module.cms_list .folder .cms_title .icon {
	margin-bottom: 5px;
}


#moduleDocuments_module.cms_list .cms_link {
	color: #15598f;
}

#moduleDocuments_module.cms_list .cms_items {
	padding: 0 10px;
}


/* videos */
#video_module.cms_list .cms_title h3 {
	margin-top: 0px;
}

#video_module.cms_list .cms_list_item .cms_video {
	padding-right: 15px;
	padding-top: 7px;
}

#video_module.cms_list .cms_list_item {
	border-bottom: 1px solid #d5e1e6;
}

#video_module.cms_list .cms_list_item {
	padding-bottom: 30px;
	margin-bottom: 26px;
}

@media (max-width: 991px) {
	#video_module.cms_list td {
		display: block;
		width: 100%;
		text-align: left;
		padding-left: 0px;
	}

	#video_module.cms_list .cms_list_item .cms_video {
		margin-bottom: 10px;
		padding-top: 0px;
		padding-right: 0px;
	}
}

/* submission forms */
#submissionforms_module.cms_form .ui-datepicker-trigger {
	left: 3px;
	top: 0px;
}

#submissionforms_module.cms_form .cms_label > div {
	display: inline;
}

#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span {
	display: block;
	padding-top: 0px;
}


#submissionforms_module.cms_form .cms_steps_wrapper .cms_step span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_first span a,
#submissionforms_module.cms_form .cms_steps_wrapper .cms_step_last span a {
	border-bottom: 0px;
}

#submissionforms_module.cms_form .sigWrapper,
#submissionforms_module.cms_form .signature_name {
	display: block !important;
}

#submissionforms_module.cms_form .sigPad input {
	padding: 4px 10px;
}

#submissionforms_module.cms_form .cms_label,
#submissionforms_module.cms_form .cms_field {
	padding-bottom: 20px;
}

#submissionforms_module.cms_form .cms_label label,
#submissionforms_module.cms_form .cms_label div {
	font-weight: bold;
}

#submissionforms_module.cms_form .formBreak,
.module-forms .formBreak {
	border: 1px solid #d5e1e6;
}

@media (max-width: 991px) {
	#submissionforms_module.cms_form .sigPad input {
		width: 100%;
	}
}

/*************** Content Boxes ********************/
/*************************************************/
/* news */
.slider #news_module.homepage_contentbox {
	text-align: left;
	margin: 140px 10px 10px 20px;
}

#news_module.homepage_contentbox .cms_title h3 {
	margin-top: 0px;
	margin-bottom: 10px;
}

#news_module.homepage_contentbox .cms_date h3 {
	font-size: 1.3rem;
	line-height: 1.3rem;
	margin-bottom: 3px;
	margin-top: 0px;
}

@media (max-width: 991px) {
	.slider #news_module.homepage_contentbox a,
	#news_module.homepage_contentbox .cms_date h3 {
		color: #fff;
		border-bottom: 0px;
	}

	#news_module.homepage_contentbox .cms_title h3 {
		margin-bottom: 3px;
	}
}

/* videos */
.homepage-featured-video {
	width: 100%;
}

.homepage-featured-video .cbTitle {
	font-weight: bold;
}

.slider .homepage-featured-video {
	text-align: left;
	margin: 140px 10px 10px 20px;
}

.homepage-featured-video iframe {
	max-width: 100%;
}

/* submission form */
.slider #submissionforms_module.homepage_contentbox {
	text-align: left;
	margin: 140px 10px 10px 20px;
}

#submissionforms_module.homepage_contentbox {
	text-align: left;
	padding: 10px;
}

#submissionforms_module.homepage_contentbox table {
	width: auto;
}

#submissionforms_module.homepage_contentbox input[type="text"],
#submissionforms_module.homepage_contentbox input[type="tel"],
#submissionforms_module.homepage_contentbox input[type="email"],
#submissionforms_module.homepage_contentbox select,
#submissionforms_module.homepage_contentbox textarea {
	width: 100%;
	max-width: 100%;
	line-height: 1.42857143;
	color: #333333;
	vertical-align: middle;
	background-color: #ffffff;
	border: 1px solid #cccccc;
	padding: 4px 10px;
	font-size: 0.875em;
}

#submissionforms_module.homepage_contentbox input[type="checkbox"],
#submissionforms_module.homepage_contentbox input[type="radio"] {
	margin-right: 3px;
}

#submissionforms_module.homepage_contentbox textarea {
	width: 100% !important;
}

#submissionforms_module.homepage_contentbox input.hasDatepicker[type="text"] {
	width: calc(100% - 40px);
	float: left;
}

#submissionforms_module.homepage_contentbox .ui-datepicker-trigger {
	position: relative;
	left: 5px;
	top: 0px;
}

/* GDPR */
#cookie-consent-form-container form .consent-header {
	text-align: center;
	background: #c0ceda;
}

#cookie-consent-form-container form .consent-header img {
	float: none;
}
